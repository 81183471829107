html {
  font-size: 62.5%;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.hamburger {
  transform: scale(1.5);
  cursor: pointer;
  color: #3c4967;
  padding: 4px;
}

.rotw-highlight {
  font-weight: 600;
  color: #3c4967;
}

.brand-sig {
  font-style:italic; 
  font-family: 'Courier New', Courier, monospace; 
  text-transform: uppercase; 
  font-weight: bold; 
  color: #ff6b6b;
}

.hamburger:hover,
.hambuerger.active {
  font-weight: 700;
  background-color: #ff6b6b;
  color: rgb(253, 252, 239);
  border-radius: 50%;
}

body {
  background-color: #fdfcef !important;
  padding: 0px;
  margin: 0px;
  position: relative;
  min-height: calc(100vh - 100px);
}

.page-container {
  display: flex;
  flex-direction: column;
  min-height: 100vh;
}

.home-container {
  width: 100%;
  display: flex;
  flex-direction: column;
  margin: 0 auto;
  flex-grow: 1;
  max-width: 150rem;
}

.logo-container {
  max-width: 80rem;
  margin: 0 auto;
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.home-content {
  display: flex;
  width: 85%;
  margin: 0 auto;
}

.right-div {
  width: 40%;
  position: relative;
}

.left-div {
  width: 60%;
}

.logo-div {
  width: 100%;
  background-color: transparent;
  display: flex;
  justify-content: center;
}

.logo-div > img {
  width: 85%;
  height: auto;
}

.CTA-header {
  color: #3c4967 !important;
  font-size: 4.5rem;
  font-weight: 600;
  margin-bottom: 2rem;
}

.CTA-div {
  width: 85%;
  margin: 0 auto;
  flex-direction: column;
}

.CTA-subheader {
  color: rgba(60, 73, 103, 0.8) !important;
  font-size: 2rem;
  margin-top: 0rem;
}

.brand {
  color: #ff6b6b;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  font-style: italic;
}

.subscribe-div {
  width: 85%;
  background-color: rgb(240, 239, 215);
  height: 5.5rem;
  border-radius: 10px;
  margin: 0 auto;
  border: 1.5px solid transparent;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

.subscribe-div.active {
  border: 1.5px solid #ff6b6b;
}
.subscribe-input {
  font-size: 2rem;
  color: rgba(60, 73, 103, 0.8) !important;
  background-color: rgb(240, 239, 215);
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
  padding-left: 1rem;
  border-radius: 1rem;
  border: none;
  width: calc(100% - 18rem);
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.subscribe-input::placeholder {
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.subscribe-input:focus {
  outline: none;
  border: none;
}

.subscribe-btn {
  background-color: #ff6b6b;
  color: #fdfcef;
  border: none;
  font-size: 2rem;
  border: 1.5px solid #ff6b6b;
  width: fit-content;
  padding: 1rem 2rem;
  height: 100%;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
  border-radius: 1rem;
  top: 0.7rem;
  cursor: pointer;
}

.subscribe-btn:hover {
  background-color: #ff4e4e;
  color: rgb(240, 239, 215);
}

.iphone-container {
  width: 80%;
  margin: 0 auto;
  position: relative;
  cursor: pointer;
}

.iphone {
  width: 100%;
  height: auto;
}
.example-email {
  width: 67%;
  z-index: -1;
  height: auto;
  position: absolute;
  top: 49%;
  left: 50%; /* This will horizontally center the image */
  transform: translate(-50%, -50%); /* This will center the image precisely */
}

.input.search {
  width: 1;
}

.autocomplete-container {
  position: absolute;
  height: 10rem;
  top: 7rem;
  background-color: rgb(240, 239, 215);
  overflow: scroll;
  border-radius: 1rem;
  min-width: 73%;
  max-width: calc(100% - 6rem);
  overflow-x: hidden;
}

.suggestion {
  font-size: 2rem;
  color: rgba(60, 73, 103, 1);
  height: 20px;
  overflow: hidden;
  width: fit-content;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
  width: 100%;
}

.suggestion > svg {
  transform: scale(1.2);
  margin-right: 5px;
}
.suggestion-active > svg {
  transform: scale(1.3);
  margin-right: 5px;
  color: white !important;
}

.suggestion:hover {
  color: white;
}

.suggestion-active {
  background-color: #ff6b6b;
  height: 20px;
  max-height: fit-content;
  width: 100%;
  overflow: hidden;
  padding: 10px;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: space-between;
}

#last-active,
#last {
  margin-top: 3rem;
}

.items {
  position: absolute;
  display: flex;
  flex-direction: column;
  padding-left: 1rem;
  border-radius: 1rem;
  height: 10rem;
  position: absolute;
  top: 7rem;
  border: 0.75px solid #ff6b6b;
  min-height: fit-content;
  background-color: rgb(240, 239, 215);
  overflow: scroll;
  align-items: flex-start;
  min-width: 73%;
  max-width: calc(100% - 6rem);
}

.items-title {
  width: 90%;
  font-size: 1.6rem;
  font-weight: 600;
  height: 3.5rem;
  color: #3c4967;
  text-decoration: underline;
}

.zips {
  width: 90%;
  display: grid;
  height: 7rem;
  grid-template-rows: 25px 25px;
  grid-template-columns: repeat(auto-fill, minmax(40px, 1fr));
  column-gap: 1rem;
  font-size: 1.4rem;
  grid-auto-flow: column;
  overflow-x: scroll;
}

.sup {
  color: #ff6b6b;
  font-size: 1rem;
  font-style: italic;
  cursor: pointer;
}
.zip {
  display: flex;
  color: rgba(60, 73, 103, 0.8);
}

.sup:hover {
  font-weight: bolder;
}

.small-p {
  margin-right: 3px;
}

.submit-loader {
  width: 390px;
  height: 55px;
  border-radius: 7.5px;
  display: flex;
  border: none;
  align-items: center;
  justify-content: center;
  background: #ff6b6b;
  opacity: 0.9;
}

.loader {
  border: 7.5px solid white;
  border-top: 7.5px solid rgba(60, 73, 103, 0.8);
  border-radius: 50%;
  width: 10px;
  margin: 0 auto;
  height: 10px;
  animation: spin 2s linear infinite;
}

@keyframes spin {
  0% {
    transform: rotate(0deg);
  }
  100% {
    transform: rotate(360deg);
  }
}

.footer {
  width: 99%;
  padding: 0.5%;
  margin-top: 2.5rem;
  left: 0;
  font-size: 1.3rem;
  background-color: rgb(240, 239, 215);
  color: rgba(60, 73, 103, 1) !important;
  margin-top: 0%;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.socials {
  display: flex;
  justify-content: space-around;
  align-items: center;
}

.social-icon {
  padding: 7.5px;
  background-color: rgb(240, 239, 215);
  height: 20px;
  width: 20px;
  border-radius: 50%;
  color: #3c4967;
}

.social-icon:hover {
  background-color: #ff6b6b;
  color: rgb(253, 252, 239);
}

.footer-container {
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 150rem;
}

.more-info {
  display: flex;
  align-items: center;
}

.more-info > a {
  padding: 0rem 0.75rem;
  cursor: pointer;
  color: rgba(60, 73, 103, 1) !important;
  text-decoration: underline;
  text-align: center;
}

.more-info > a:hover {
  color: #28333f;
}

.page-title {
  font-size: 5rem;
  color: rgba(60, 73, 103, 1);
  font-style: italic;
  margin: 0 auto;
  text-align: center;
  width: 90%;
  margin: 0 auto;
}

.page-content {
  width: 50%;
  margin: 0 auto;
  font-size: 14px;
  color: rgba(60, 73, 103, 0.8) !important;
  text-align: center;
}

.page-content-title {
  font-weight: 700;
  padding-top: 1rem;
  font-size: 1.8rem;
  padding-bottom: 0.5rem;
}

.page-content-text {
  font-size: 1.6rem;
}

.separate.advertise {
  width: 50%;
}

.highlight1 {
  color: rgba(60, 73, 103, 1);
  font-weight: 600;
}

.separate {
  border-top: 2px dotted #ff6b6b !important;
  border-bottom: none !important;
  border-left: none !important;
  border-right: none !important;
  width: 55%;
  margin-top: 2rem !important;
  margin-bottom: 2rem !important;
}

.page-form {
  width: 50%;
  margin: 0 auto;
  margin-top: 1rem;
}

.input.partner {
  width: 98%;
  font-size: 2rem;
  padding: 1.5rem 0rem;
  background-color: rgb(240, 239, 215);
  border-radius: 1rem;
  padding-left: 1rem;
  border: 1.5px solid transparent;
  margin-top: 3rem;
  color: rgba(60, 73, 103, 0.8);
}

.input.partner:focus {
  border: 1.5px solid #ff6b6b;
  outline: none;
}

.select-input {
  width: 100%;
  border: none;
  font-size: 2rem;
  padding: 1.5rem 0rem;
  border-radius: 1rem;
  background-color: rgb(240, 239, 215);
  padding-left: 1rem;
  color: rgba(60, 73, 103, 0.8);
  border: 1.5px solid transparent;
}
.select-input:focus {
  outline: none;
  border: 1.5px solid #ff6b6b;
}

.option-div > p {
  color: rgba(60, 73, 103, 0.8);
  font-size: 1.6rem;
  padding-left: 1rem;
  font-style: italic;
}
.option-div {
  margin-top: 3rem;
}

.option-div.optional {
  margin-top: 0rem !important;
  margin-left: 2rem;
}

.submit.partner {
  font-size: 2rem;
  height: 5.5rem;
  display: flex;
  align-items: center;
  width: 100%;
  margin: 0 auto;
  margin-top: 3rem;
  border-radius: 1rem;
  justify-content: space-between;
  color: white;
  background-color: #ff6b6b;
  cursor: pointer;
}

.submit.partner:hover {
  background-color: #ff4e4e;
  color: rgb(240, 239, 215);
}

.text-btn {
  padding-left: 1rem;
}

.highlight-link {
  color: #ff6b6b !important;
}

.bouncing-loader {
  position: absolute;
  right: 1rem;
  top: 2rem;
  display: flex;
  justify-content: center;
}

.bouncing-loader > div {
  width: 0.7rem;
  height: 0.7rem;
  margin: 2.5px 5px;
  border-radius: 50%;
  background-color: #ff6b6b;
  opacity: 1;
  animation: bouncing-loader 1s infinite alternate;
}

@keyframes bouncing-loader {
  to {
    opacity: 0.5;
    transform: translateY(5px);
  }
}

.bouncing-loader > div:nth-child(2) {
  animation-delay: 0.2s;
}

.bouncing-loader > div:nth-child(3) {
  animation-delay: 0.4s;
}

.header {
  width: 99%;
  padding: 0.5%;
  left: 0;
  top: 0;
  position: sticky;
  z-index: 999;
  font-size: 1.3rem;
  background-color: rgb(240, 239, 215);
  color: rgba(60, 73, 103, 1) !important;
  border-bottom: 2px solid rgba(60, 73, 103, 1);
}

.header-container {
  width: 95%;
  margin: 0 auto;
  display: flex;
  align-items: center;
  justify-content: space-between;
  max-width: 150rem;
}

.subscribed.remain {
  font-size: 2rem;
  padding: 0rem 1.5rem;
  background-color: #ff6b6b;
  color: rgb(253, 252, 239);
  padding-left: 1rem;
  width: 80%;
  margin: 0 auto;
  border-radius: 1rem;
  margin-top: 1.5rem;
  border: 1.5px solid #fdfcef;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.subscribed.remain:hover {
  background-color: #ff4e4e;
  color: rgb(240, 239, 215);
}

.subscribed.cancel {
  font-size: 2rem;
  padding: 0rem 1.5rem;
  background-color: #fdfcef;
  color: #ff6b6b;
  padding-left: 1rem;
  border-radius: 1rem;
  width: 80%;
  margin: 0 auto;
  margin-top: 1.5rem;
  border: 1.5px solid #ff6b6b;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: flex-start;
  position: relative;
}

.subscribed.cancel:hover {
  color: #ff4e4e;
  background-color: rgb(240, 239, 215);
}

.subscribed.cancel.inactive,
.remain.subscribed.inactive,
.page-content-title.inactive {
  opacity: 0.4;
}

.unsub-arrow {
  position: absolute;
  right: 2rem;
  transform: scale(1.75);
}

.sold-logo {
  width: 12.5rem;
  cursor: pointer;
}

.unsubscribe-div-action {
  font-size: 2rem;
  padding: 0rem 1.5rem;
  height: 5rem;
  background-color: #fdfcef;
  color: #ff6b6b;
  padding-left: 1rem;
  border-radius: 1rem;
  width: 80%;
  margin: 0 auto;
  margin-top: 1.5rem;
  border: 1.5px solid #ff6b6b;
  cursor: pointer;
}

.subscribe-div.unsubscribe {
  width: 84%;
  margin-top: 3rem;
}

.subscribe-input.unsubscribe {
  min-width: calc(100% - 19.5rem);
}

.separate.unsubscribe {
  width: 50%;
  margin: 0 auto;
  margin-top: 3rem !important;
}

.example-copy {
  width: 80%;
  margin: 0 auto;
  display: flex;
  padding: 0rem;
  justify-content: center;
  font-size: 2rem;
  color: rgba(60, 73, 103, 0.8) !important;
  font-weight: 600;
  font-style: italic;
}

.example-copy > p {
  margin-top: 1.5rem;
  margin-bottom: 0rem;
}

.brand.click {
  text-decoration: underline;
  cursor: pointer;
  margin-right: 0.15rem;
  color: #ff6b6b;
}

.brand.click:hover {
  color: #ff4e4e;
}

.example-copy.top {
  visibility: collapse;
}

.separate.unsubscribe.home {
  visibility: collapse;
}

.navigation-page {
  position: fixed;
  top: 0;
  right: -100%;
  width: 100%;
  height: 100%;
  background-color: rgb(240, 239, 215);
  display: flex;
  justify-content: flex-end;
  align-items: flex-start;
  transition: right 2s ease-in-out, opacity 2s ease-in-out;
  opacity: 0;
}
.navigation-page.active {
  right: 0;
  opacity: 1;
}

.navigation-page-container {
  background-color: rgb(240, 239, 215);
  display: flex;
  flex-direction: column;
  width: 100%;
  height: 100%;
  margin: 0 auto;
  overflow-y: auto;
  max-width: 150rem;
}

.navigation-items {
  width: 94%;
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  text-transform: uppercase;
  margin: 0 auto;
  display: flex;
  flex-direction: column;
  align-items: flex-end;
}

.navigation-items > a {
  padding: 2rem;
  text-decoration: none;
  color: rgba(60, 73, 103, 1);
  border-bottom: 2px solid transparent;
  font-size: 3.5rem;
  transition: padding-right 0.4s ease-in-out;
}

.navigation-items > a:hover {
  padding-right: 3.5rem;
  color: #ff6b6b;
  font-style: italic;
  font-weight: 600;
}

.navigation-items > a.active {
  padding-right: 3.5rem;
  color: #ff6b6b;
  font-style: italic;
  font-weight: 600;
}

.header-container.nav {
  padding-top: 1.4rem;
}

.close-icon {
  cursor: pointer;
  transform: scale(1.5);
  color: #3c4967;
  padding: 4px;
}

.close-icon:hover,
.close-icon.active {
  font-weight: 700;
  background-color: #ff6b6b;
  color: rgb(253, 252, 239);
  border-radius: 50%;
}

.subscribe-btn.unsub {
  width: fit-content;
  padding: 1rem 1.2rem;
}

@media (max-width: 1375px) {
  .iphone-container,
  .example-copy {
    width: 36rem;
  }

  .CTA-header {
    font-size: 4rem;
  }

  .items-title {
    font-size: 1.4rem;
  }

  .zips {
    font-size: 1.3rem;
  }

  .sup {
    font-size: 0.9rem;
  }

  .CTA-subheader,
  .subscribe-input,
  .subscribe-btn,
  .example-copy,
  .suggestion {
    font-size: 1.8rem;
  }

  .logo-div > img {
    width: 77%;
    height: auto;
  }
}

@media (max-width: 900px) {
  .iphone-container,
  .example-copy {
    width: 32rem;
  }

  .navigation-items > a {
    font-size: 3rem;
  }

  .navigation-items > a:hover {
    padding-right: 3rem;
  }

  .header-container.nav {
    padding-top: 0.9rem;
  }

  .CTA-header {
    font-size: 3.5rem;
  }

  .CTA-subheader,
  .subscribe-input,
  .subscribe-btn,
  .example-copy,
  .suggestion {
    font-size: 1.6rem;
  }

  .subscribe-div {
    height: 4.6rem;
  }

  .items-title {
    font-size: 1.3rem;
  }

  .zips {
    font-size: 1.2rem;
  }

  .sup {
    font-size: 0.95rem;
  }

  .subscribe-input {
    padding: 0.9rem 1.8rem;
  }

  .subscribe-btn {
    width: fit-content;
  }

  .logo-div > img {
    width: 68%;
    height: auto;
  }
}

@media (max-width: 860px) {
  .subscribe-btn.unsub {
    font-size: 1.5rem;
    width: fit-content;
  }
}

@media (max-width: 800px) {
  .iphone-container,
  .example-copy {
    width: 28rem;
  }

  .CTA-header {
    font-size: 3.25rem;
  }

  .CTA-subheader,
  .subscribe-input,
  .subscribe-btn,
  .example-copy,
  .suggestion {
    font-size: 1.5rem;
  }

  .header-container.nav {
    padding-top: 0.7rem;
  }

  .navigation-items > a {
    font-size: 2.5rem;
  }

  .navigation-items > a:hover {
    padding-right: 2.5rem;
  }

  .logo-div > img {
    width: 65%;
    height: auto;
  }

  .items-title {
    font-size: 1.25rem;
  }

  .zips {
    font-size: 1.1rem;
  }

  .sup {
    font-size: 0.9rem;
  }

  .subscribe-div {
    height: 4.6rem;
  }

  .subscribe-input {
    padding: 0.9rem 1.8rem;
  }

  .subscribe-btn {
    width: fit-content;
  }

  .footer {
    font-size: 1.1rem;
  }

  .sold-logo {
    width: 10rem;
  }
}

@media (max-width: 720px) {
  .iphone-container,
  .example-copy {
    width: 26rem;
  }

  .page-title {
    font-size: 4rem;
  }

  .subscribe-btn.unsub {
    font-size: 1.2rem;
    width: fit-content;
  }

  .submit.partner {
    font-size: 1.6rem;
    height: 4.4rem;
  }

  .subscribed.remain,
  .subscribed.cancel {
    font-size: 1.6rem;
    padding-left: 1rem;
  }

  .page-content {
    width: 80%;
    font-size: 1.1rem;
  }

  .page-content-title {
    font-size: 1.45rem;
  }

  .page-content-text {
    font-size: 1.3rem;
  }

  .option-div > p {
    font-size: 1.28rem;
  }

  .page-form {
    width: 80%;
  }

  .input.partner,
  .select-input {
    font-size: 1.6rem;
    padding: 1.2rem 0rem;
    padding-left: 1rem;
  }

  .CTA-header {
    font-size: 3rem;
  }

  .CTA-subheader,
  .subscribe-input,
  .subscribe-btn,
  .example-copy,
  .suggestion {
    font-size: 1.4rem;
  }

  .logo-div > img {
    width: 65%;
    height: auto;
  }

  .subscribe-div {
    height: 4.6rem;
  }

  .subscribe-input {
    padding: 0.9rem 1.8rem;
  }

  .subscribe-btn {
    width: fit-content;
  }

  .footer {
    font-size: 1.1rem;
  }

  .sold-logo {
    width: 10rem;
  }
}

@media (max-width: 690px) {
  .iphone-container,
  .example-copy {
    width: 26rem;
  }

  .CTA-header {
    font-size: 2.8rem;
  }

  .CTA-subheader,
  .subscribe-input,
  .subscribe-btn,
  .example-copy,
  .suggestion {
    font-size: 1.3rem;
  }

  .logo-div > img {
    width: 65%;
    height: auto;
  }

  .subscribe-input {
    width: calc(100% - 14.5rem);
  }

  .subscribe-btn {
    width: fit-content;
  }

  .subscribe-div {
    height: 4.6rem;
  }

  .subscribe-input {
    padding: 0.9rem 1.8rem;
  }

  .sold-logo {
    width: 10rem;
  }

  .footer {
    font-size: 1rem;
  }

  .more-info > a {
    padding: 0 0.5rem;
  }

  .social-icon {
    padding: 6px;
    height: 17.5px;
    width: 17.5px;
  }

  .sold-logo {
    width: 9rem;
  }
}

@media (max-width: 630px) {
  .iphone-container,
  .example-copy {
    width: 23rem;
  }

  .CTA-header {
    font-size: 2.5rem;
  }

  .CTA-subheader,
  .subscribe-input,
  .subscribe-btn,
  .example-copy,
  .suggestion {
    font-size: 1.15rem;
  }

  .logo-div > img {
    width: 65%;
    height: auto;
  }

  .subscribe-btn {
    width: fit-content;
  }

  .subscribe-div {
    height: 4.2rem;
  }

  .subscribe-input {
    padding: 0.8rem 1.6rem;
  }

  .subscribe-input {
    width: calc(100% - 14.5rem);
  }

  .footer {
    font-size: 0.9rem;
  }

  .social-icon {
    height: 15px;
    width: 15px;
    padding: 0.5rem 0.5rem;
  }
}

@media (max-width: 560px) {
  .iphone-container,
  .example-copy {
    width: 21rem;
  }

  .home-content {
    flex-direction: column;
    align-items: center;
    width: 90%;
  }
  .left-div {
    width: 100% !important;
  }

  .right-div {
    width: 100% !important;
  }

  .iphone-container,
  .example-copy {
    width: 80% !important;
  }

  .CTA-subheader,
  .subscribe-input,
  .example-copy,
  .suggestion {
    font-size: 2rem;
  }

  .subscribe-btn {
    font-size: 1.8rem;
  }

  .subscribe-div {
    height: 5rem;
  }

  .subscribe-input {
    width: calc(100% - 18rem);
  }

  .subscribe-btn {
    width: fit-content;
  }

  .logo-div > img {
    width: 80%;
    height: auto;
  }

  .items-title {
    font-size: 1.2rem;
  }

  .zips {
    font-size: 1.1rem;
  }

  .sup {
    font-size: 0.8rem;
  }

  .example-copy.bottom {
    visibility: collapse;
  }

  .example-copy.top {
    visibility: visible;
    margin-bottom: 2rem;
  }

  .separate.unsubscribe.home {
    visibility: visible;
  }

  #last-active {
    margin-bottom: 15rem;
  }
}

@media (max-width: 530px) {
  .footer {
    font-size: 0.8rem;
  }

  .items,
  .autocomplete-container {
    height: 8rem;
    overflow-y: scroll;
    top: 5.5rem;
  }
}

@media (max-width: 450px) {
  .more-info > a {
    padding: 0 0.3rem;
  }

  .subscribe-btn.unsub {
    font-size: 1rem;
    padding: 0rem;
    width: fit-content;
  }

  .social-icon {
    padding: 0.35rem 0.35rem;
    height: 10px;
    width: 10px;
  }

  .iphone-container,
  .example-copy {
    width: 75% !important;
  }

  .items,
  .autocomplete-container {
    top: 5.25rem !important;
    max-height: 7rem !important;
    overflow-y: scroll;
  }

  .CTA-subheader,
  .subscribe-input,
  .subscribe-btn,
  .example-copy,
  .suggestion {
    font-size: 1.5rem;
  }

  .subscribe-btn {
    font-size: 1.4rem;
  }

  .items-title {
    font-size: 1.1rem;
  }

  .zips {
    font-size: 1rem;
  }

  .sup {
    font-size: 0.75rem;
  }

  .subscribe-div {
    height: 4.5rem;
  }
}

@media (max-width: 425px) {
  .more-info > a {
    padding: 0 0.2rem;
  }

  .subscribed.remain,
  .subscribed.cancel {
    font-size: 1.2rem;
    padding-left: 1rem;
  }

  .page-title {
    font-size: 3.5rem;
  }

  .page-content {
    width: 80%;
    font-size: 0.96rem;
  }

  .page-content-title {
    font-size: 1.27rem;
  }

  .page-content-text {
    font-size: 1.14rem;
  }

  .input.partner,
  .select-input {
    font-size: 1.4rem;
    padding: 1.05rem 0rem;
    padding-left: 1rem;
  }

  .social-icon {
    padding: 0 0.25rem;
    height: 10px;
    width: 10px;
  }

  .option-div > p {
    font-size: 1.12rem;
  }

  .subscribe-btn {
    width: fit-content;
  }

  .submit.partner {
    font-size: 1.4rem;
    height: 3.9rem;
  }

  .iphone-container,
  .example-copy {
    width: 75% !important;
  }

  .CTA-subheader,
  .subscribe-input,
  .subscribe-btn,
  .example-copy,
  .suggestion {
    font-size: 1.25rem;
  }

  .subscribe-div {
    height: 4rem;
  }

  .footer {
    font-size: 0.65rem;
  }

  .social-icon {
    padding: 0.35rem 0.35rem;
    height: 0.8rem;
    width: 0.8rem;
  }

  .sold-logo {
    width: 7.5rem;
  }
}

@media (max-width: 325px) {
  .subscribed.remain,
  .subscribed.cancel {
    font-size: 1rem;
    padding-left: 1rem;
  }
}
