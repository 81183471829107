.social-proof-div,
.subscribe-cta-div,
.features-div {
  width: 100%;
  padding: 6rem 0rem;
}

.social-proof-container,
.subscribe-cta-container,
.features-container {
  width: 85%;
  margin: 0 auto;
  max-width: 150rem;
  display: flex;
  flex-direction: column;
  align-items: center;
}

.widget-title {
  color: #3c4967 !important;
  font-size: 4rem;
  font-weight: 600;
  text-align: center;
  margin: 0;
  margin-bottom: 3rem;
}

.social-proof-logos {
  width: 85%;
  height: auto;
  display: flex;
  justify-content: space-around;
}

.social-proof-logo {
  width: 30%;
  height: auto;
  display: flex;
  align-items: center;
  justify-content: center;
}

.social-proof-logo > img {
  width: 60%;
  height: auto;
}

.widget-subtitle {
  font-size: 2.5rem;
  font-weight: 500;
  color: rgba(60, 73, 103, 0.8);
  margin: 0;
  margin-bottom: 3rem;
  text-align: center;
}

.features-div,
.social-proof-div {
  background-color: rgb(240, 239, 215);
}

.subscribe-cta-container > .subscribe-div {
  max-width: 70rem;
}

.features-content-container {
  display: flex;
  width: 85%;
  max-width: 150rem;
  justify-content: space-around;
}

.quote {
  font-size: 2.5rem;
  color: rgba(253, 252, 239, 1);
  text-align: center;
  line-height: 150%;
  width: 80%;
  margin: 0 auto;
}

.feature-div {
  width: 27.5%;
  margin-top: 1rem;
  padding: 3rem 1rem;
  display: flex;
  flex-direction: column;
  align-items: center;
  background-color: #3c4967;
  box-shadow: 0 0 12px #ff6b6b;
  border-radius: 1rem;
  font-family: "Lucida Sans", "Lucida Sans Regular", "Lucida Grande",
    "Lucida Sans Unicode", Geneva, Verdana, sans-serif;
}

.feature-icon {
  background-color: #ff6b6b;
  transform: scale(2.5);
  margin-top: 2rem;
  color: rgb(253, 252, 239);
  padding: 0.4rem;
  border-radius: 50%;
  margin-bottom: 4rem;
}

.feature-div-content {
  display: flex;
  flex-direction: column;
  text-align: center;
}
.feature-div-content-title {
  font-size: 2.5rem;
  color: rgb(253, 252, 239);
  font-family: "Courier New", Courier, monospace;
  font-weight: bold;
  text-transform: uppercase;
  text-decoration: underline;
  text-decoration-color: #ff6b6b;
  font-style: italic;
  margin-bottom: 1;
  margin-top: 0;
}

.feature-div-content-content {
  text-align: start;
  margin-top: 0;
  width: 80%;
  margin: 0 auto;
  margin-bottom: 1.5rem;
  text-align: center;
  line-height: 150%;
  font-size: 1.7rem;
  color: rgba(253, 252, 239, 0.8);
}

.feature-highlight {
  color: rgba(253, 252, 239, 1);
  font-weight: 600;
}

@media (max-width: 1000px) {
  .social-proof-div,
  .subscribe-cta-div,
  .features-div {
    padding: 5rem 0rem;
  }

  .widget-title {
    font-size: 3.25rem;
  }

  .widget-subtitle {
    font-size: 2.03rem;
  }

  .feature-icon {
    transform: scale(2.03);
  }

  .feature-div-content-title,
  .quote {
    font-size: 1.83rem;
  }

  .feature-div-content-content {
    font-size: 1.22rem;
  }
}

@media (max-width: 850px) {
  .social-proof-div,
  .subscribe-cta-div,
  .features-div {
    padding: 4.5rem 0rem;
  }

  .widget-title {
    font-size: 2.75rem;
  }

  .widget-subtitle {
    font-size: 1.74rem;
  }

  .feature-icon {
    transform: scale(1.74);
    margin-bottom: 3rem;
    margin-top: 1.25rem;
  }

  .feature-div-content-title,
  .quote {
    font-size: 1.6rem;
  }

  .feature-div-content-content {
    font-size: 1.4rem;
  }
}

@media (max-width: 750px) {
  .social-proof-div,
  .subscribe-cta-div,
  .features-div {
    padding: 4rem 0rem;
  }

  .widget-title {
    font-size: 2.25rem;
    margin-bottom: 0.75rem;
  }

  .widget-subtitle {
    font-size: 1.43rem;
    margin-bottom: 2rem;
  }

  .widget-title.subscribe {
    margin-bottom: 2rem;
  }

  .feature-icon {
    transform: scale(1.43);
    margin-bottom: 2.5rem;
    margin-top: 1.5rem;
  }

  .feature-div-content-title,
  .quote {
    font-size: 1.27rem;
  }

  .feature-div-content-content {
    font-size: 0.85rem;
  }
}

@media (max-width: 600px) {
  .social-proof-div,
  .subscribe-cta-div,
  .features-div {
    padding: 3.75rem 0rem;
  }
  .widget-title {
    font-size: 2rem;
    margin-bottom: 0.75rem;
  }

  .widget-subtitle {
    font-size: 1.27rem;
    margin-bottom: 1.75rem;
  }

  .feature-icon {
    transform: scale(1.27);
    margin-bottom: 2.5rem;
    margin-top: 0.5rem;
  }

  .feature-div-content-title,
  .quote {
    font-size: 1.13rem;
  }

  .feature-div-content-content {
    font-size: 0.76rem;
  }
}

@media (max-width: 560px) {
  .social-proof-div,
  .subscribe-cta-div,
  .features-div {
    padding: 3.5rem 0rem;
  }

  .features-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .feature-div {
    width: 90%;
    padding: 2rem;
    margin-bottom: 1.5rem;
  }

  .feature-div.three {
    margin-bottom: 0rem;
  }

  .widget-title {
    font-size: 2.25rem;
    margin-bottom: 0.75rem;
  }

  .widget-subtitle {
    font-size: 1.6rem;
    margin-bottom: 1.75rem;
  }

  .feature-icon {
    transform: scale(1.9);
    margin-bottom: 2.5rem;
    margin-top: 1rem;
  }

  .feature-div-content-title,
  .quote {
    font-size: 2rem;
  }

  .feature-div-content-content {
    font-size: 1.4rem;
  }
}

@media (max-width: 400px) {
  .social-proof-div,
  .subscribe-cta-div,
  .features-div {
    padding: 3.5rem 0rem;
  }

  .features-content-container {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .feature-div {
    width: 90%;
    padding: 2rem;
    margin-bottom: 1.5rem;
  }

  .feature-div.three {
    margin-bottom: 0rem;
  }

  .widget-title {
    font-size: 2rem;
    margin-bottom: 0.75rem;
  }

  .widget-subtitle {
    font-size: 1.42rem;
    margin-bottom: 1.5rem;
  }

  .feature-icon {
    transform: scale(1.69);
    margin-bottom: 2.5rem;
    margin-top: 1rem;
  }

  .feature-div-content-title,
  .quote {
    font-size: 1.51rem;
  }

  .feature-div-content-content {
    font-size: 1.25rem;
  }
}
